// https://github.com/stimulus-components/stimulus-scroll-to

import { Controller } from "@hotwired/stimulus"

// interface Option {
//   offset?: number
//   behavior?: string
// }

export default class extends Controller {
  // offset: number
 //  behavior: string
 //
 //  // @ts-ignore
 //  element: HTMLAnchorElement
 //  offsetValue: number
 //  behaviorValue: string
 //
 //  static values = {
 //    offset: Number,
 //    behavior: String
 //  }

  initialize () {
    this.scroll = this.scroll.bind(this)
  }

  connect () {
    console.log("scroll_to")
    this.element.addEventListener('click', this.scroll)

    this.offset = this.offsetValue || this.defaultOptions.offset || 20
    this.behavior = this.behaviorValue || this.defaultOptions.behavior || 'smooth'
  }

  disconnect () {
    this.element.removeEventListener('click', this.scroll)
  }

  scroll (event) {
    event.preventDefault()

    const id = this.element.hash.replace(/^#/, '')
    const target = document.getElementById(id)
    
    
    if (!target) {
      console.warn(`[stimulus-scroll-to] The element with the id: "${id}" does not exist on the page.`)
      return
    }

    const elementPosition = target.getBoundingClientRect().top + window.pageYOffset
    const offsetPosition = elementPosition - this.offset

    window.scrollTo({
      top: offsetPosition,
      behavior: this.behavior
    })
    
  }

  get defaultOptions (){
    return {}
  }
}