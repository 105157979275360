import { Controller } from "@hotwired/stimulus"

// <div data-controller="accordion">
//   <div> <!-- parent must be a div -->
//     <h2>My title</h2>
//     <a href="#" data-action="accordion#toggle">Preview</a>
//     <div class="dnone" data-target="accordion.source">
//       Text to be toggled
//     </div>
//   </div>
// </div>

export default class extends Controller {

  static targets = [ "source", "icon" ]
  
  connect() {
    this.closeAllBefore = this.data.get("close-all-before") || "false"
    this.classToToggle = this.data.get("toggle-class") || "hidden"
    this.toggleIconClass  = this.data.get("toggle-icon") || "fa-chevron-up"
  }

  toggle(event) {
    event.preventDefault()
    if (this.closeAllBefore === 'true') {
      this.closeOpenElements()
    }
    this.toggleClassElement(event.target.closest('div'))
  }

  closeOpenElements() {
    var klass = this.classToToggle
    this.sourceTargets.forEach(function(item) {
      item.classList.add(klass)
    });
  }

  toggleClassElement(item) {
    item.scrollIntoView({block: 'start',  behavior: 'smooth', inline: "nearest"})
    
    item.querySelector('[data-accordion-target="source"]').classList.toggle(this.classToToggle)
    // item.querySelector('[data-accordion-target="icon"]').classList.toggle("fa-chevron-right")
    // item.querySelector('[data-accordion-target="icon"]').classList.toggle(this.toggleIconClass)
  }
}


// import { Controller } from "@hotwired/stimulus"
//
// export default class extends Controller {
//   static targets = [ "pointer", "content", "icon" ]
//
//   connect() {
//     this.activeClass      = this.data.get("activeClass")      || "hidden"
//     this.toggleIconClass  = this.data.get("toggleIconClass")  || "fa-chevron-up"
//   }
//
//   toggle(event) {
//     event.preventDefault()
//     this.index = this.pointerTargets.indexOf(event.currentTarget)
//   }
//
//   showContent() {
//
//     this.pointerTargets.forEach((pointer, index) => {
//       const content = this.contentTargets[index]
//       const icon    = this.iconTargets[index]
//       content.classList.toggle(this.activeClass, index == this.index)
//       console.log(this.index)
//
//       if (index === this.index) {
//         icon.classList.add(this.toggleIconClass)
//       } else {
//         icon.classList.remove(this.toggleIconClass)
//       }
//     })
//   }
//
//   get index() {
//     return parseInt(this.data.get("index") || 0)
//   }
//
//   set index(value) {
//     this.data.set("index", value)
//     this.showContent()
//   }
// }
//
