import { Controller } from '@hotwired/stimulus'

// import { SwiperOptions } from 'swiper'
import Swiper from 'swiper/bundle'

export default class extends Controller {
  

  connect() {
    this.swiper = new Swiper(this.element, {
      direction: 'horizontal',
      slidesPerView: 1,
      speed: 500,
        
      loop: true,
      navigation: {
        nextEl: ".button-next",
        prevEl: ".button-prev",
      },
      pagination: {
        el: '.swiper-pagination',
      },        
    });
          
  }
  
  disconnect() {

    this.swiper.destroy()
  }
  
  //https://www.stimulus-components.com/docs/stimulus-carousel/
  // static targets = ["picker", "input"]
  
  // swiper: Swiper
  // optionsValue: SwiperOptions

  // static values = {
  //   options: Object
  // }
  
    //
  // connect (): void {
  //   this.swiper = new Swiper(this.element, {
  //     ...this.defaultOptions,
  //     ...this.optionsValue
  //   })
  // }
  //
  // disconnect (): void {
  //   this.swiper.destroy()
  //   this.swiper = undefined
  // }
  //
  // get defaultOptions (): SwiperOptions {
  //   return {}
  // }
}