import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]

  initialize() {
    this.activeTabClasses = (this.data.get("activeTab")  || "active").split(" ")
    this.hiddenClass      = this.data.get("hiddenClass") || "hidden"
    this.showTab()
  }

  change(event) {
    event.preventDefault()
    this.index = this.tabTargets.indexOf(event.currentTarget)
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index]
      panel.classList.toggle(this.hiddenClass, index != this.index)

      if (index === this.index) {
        tab.classList.add(...this.activeTabClasses)
      } else {
        tab.classList.remove(...this.activeTabClasses)
      }
    })
  }

  get index() {
    return parseInt(this.data.get("index") || 0)
  }

  set index(value) {
    this.data.set("index", value)
    this.showTab()
  }
}

// export default class extends Controller {
//   //https://github.com/excid3/tailwindcss-stimulus-components
//
//   // %div{"data-controller" => "tabs", "data-tabs-active-tab" => "border-opacity-100 font-bold", "data-tabs-inactive-tab" => "border-opacity-50"}
//   //   %ul.flex.w-full.text-center
//   //     %li.flex-1{"data-action" => "click->tabs#change", "data-tabs-target" => "tab"}
//   //       %a.block.leading-loose.py-1.px-4.text-white.uppercase.text-sm.tracking-wider.border-b.border-white.hover:border-opacity-100{:href => "#"} Algemeen
//   //
//   //     %li.flex-1{"data-action" => "click->tabs#change", "data-tabs-target" => "tab"}
//   //       %a.block.leading-loose.py-1.px-4.text-white.uppercase.text-sm.border-b.border-white.hover:border-opacity-100{:href => "#"} SEO
//   //
//   //   .p-4{"data-tabs-target" => "panel"}
//   //   .hidden.p-4{"data-tabs-target" => "panel"}
//
//
//   static targets = ['tab', 'panel']
//
//   connect() {
//     this.activeTabClasses = (this.data.get('activeTab') || 'active').split(' ')
//     this.inactiveTabClasses = (this.data.get('inactiveTab') || 'inactive').split(' ')
//     if (this.anchor) this.index = this.tabTargets.findIndex((tab) => tab.id === this.anchor)
//     this.showTab()
//   }
//
//   change(event) {
//     event.preventDefault()
//
//     // If target specifies an index, use that
//     if (event.currentTarget.dataset.index) {
//       this.index = event.currentTarget.dataset.index
//     // If target specifies an id, use that
//     } else if (event.currentTarget.dataset.id) {
//       this.index = this.tabTargets.findIndex((tab) => tab.id == event.currentTarget.dataset.id)
//     // Otherwise, use the index of the current target
//     } else {
//       this.index = this.tabTargets.indexOf(event.currentTarget)
//     }
//
//     window.dispatchEvent(new CustomEvent('tsc:tab-change'))
//   }
//
//   showTab() {
//     this.tabTargets.forEach((tab, index) => {
//       const panel = this.panelTargets[index]
//
//       if (index === this.index) {
//         panel.classList.remove('hidden')
//         tab.classList.remove(...this.inactiveTabClasses)
//         tab.classList.add(...this.activeTabClasses)
//
//         // Update URL with the tab ID if it has one
//         // This will be automatically selected on page load
//         if (tab.id) {
//           location.hash = tab.id
//         }
//       } else {
//         panel.classList.add('hidden')
//         tab.classList.remove(...this.activeTabClasses)
//         tab.classList.add(...this.inactiveTabClasses)
//       }
//     })
//   }
//
//   get index() {
//     return parseInt(this.data.get('index') || 0)
//   }
//
//   set index(value) {
//     this.data.set('index', (value >= 0 ? value : 0))
//     this.showTab()
//   }
//
//   get anchor() {
//     return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
//   }
// }