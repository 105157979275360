import { Controller } from '@hotwired/stimulus'

// <div data-controller='toggle' class="m-2">
//   <div data-action='click->toggle#toggle touch->toggle#toggle' class="bg-gray-900 text-white p-6">
//     What is the question?
//   </div>
//   <div data-toggle-target='toggleable' class="m-4 hidden">
//     <p>This is the answer</p>
//   </div>
// </div>

// Toggle only hide or show
// <div data-action='click->toggle#hide touch->toggle#hide' class="bg-gray-900 text-white p-6">
//   What is the question?
// </div>


export default class extends Controller {
  static targets = ['toggleable']
  static values = { open: Boolean }

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
  }

  toggle(event) {
    event.preventDefault()
    this.openValue = !this.openValue
  }

  hide(event) {
    event.preventDefault();
    this.openValue = false;
  }

  show(event) {
    event.preventDefault();
    this.openValue = true;
  }

  openValueChanged() {
    if (!this.toggleClass) { return }

    this.toggleableTargets.forEach(target => {
      target.classList.toggle(this.toggleClass)
    })
  }
}