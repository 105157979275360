// require("@hotwired/turbo-rails")
// window.Turbo = Turbo

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false
window.Turbo = Turbo


/***************** 
Controllers
*****************/
import { Application } from "@hotwired/stimulus"
window.Stimulus = Application.start()

import AccordionController from "./controllers/accordion_controller"
Stimulus.register("accordion", AccordionController)

import CartController from "./controllers/cart_controller"
Stimulus.register("cart", CartController)

import ModalController from "./controllers/modal_controller"
Stimulus.register("modal", ModalController)

import ScrollToController from "./controllers/scroll_to_controller"
Stimulus.register("scroll_to", ScrollToController)

import CarouselController from "./controllers/carousel_controller"
Stimulus.register("carousel", CarouselController)

import TabsController from "./controllers/tabs_controller.js"
Stimulus.register("tabs", TabsController)

import ToggleController from "./controllers/toggle_controller.js"
Stimulus.register("toggle", ToggleController)

import MapsController from "./controllers/maps_controller.js"
Stimulus.register("maps", MapsController)

//*************************************
// Imports
//*************************************
import { Linear, TweenMax, TimelineMax, Expo, Back, Sine, Power0, Power3, TweenLite, gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

//Libraries
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

import imagesLoaded from 'imagesloaded';
import Swiper from 'swiper/bundle';
import Scrollbar from 'smooth-scrollbar'
let bodyScrollBar;

// Lazyload Images
import 'lazysizes';

//Helpers to select elements
const select = (e) => document.querySelector(e);
const selectAll = (e) => document.querySelectorAll(e);

//Loader
const loader = select('.loader');
const loaderInner = select('.loader .inner');
const loaderMask = select('.loader__mask');
const progressBar = select('.loader .progress');


//*************************************
// Init
//************************************* 

document.addEventListener('turbo:before-render', (event) => {
  // when we are *about* to render, start us faded out
  // event.detail.newBody.classList.add('fade-out');
  console.log("Before render Loaded")    
  
  // select('body').classList.add('is-loading');
});
  
  
document.addEventListener("turbo:render", function() {
  console.log("Before render Loaded")    
  // select('body').classList.remove('is-loading')
})
  
 
  
window.addEventListener('load', function(){
  // console.log("full load");
    
  var barb =  document.getElementById('barba-wrapper');
  if (typeof(barb) != 'undefined' && barb != null){      
    init();    
  } else {    
    initContent();
  }
      
});
    
function init(){
  //Fix mobile height
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
  
  initPageTransitions();
  // initContent();
  
  // // show loader on page load
  // gsap.set(loader, {autoAlpha: 1});
  //
  // // scale loader down
  // gsap.set(loaderInner, {scaleY: 0.005, transformOrigin: 'bottom'});
  //
  // // make a tween that scales the loader
  // const progressTween = gsap.to(progressBar, {paused: true, scaleX: 0, ease: 'none', transformOrigin: 'right'});
  //
  // // setup variables
  // // https://codepen.io/desandro/pen/hlzaw
  // let loadedImageCount = 0, imageCount;
  // const container = select('#barba-wrapper');
  //
  // // setup Images loaded
  // const imgLoad = imagesLoaded( container, { background: true } );
  // imageCount = imgLoad.images.length;
  //
  // // set the initial progress to 0
  // updateProgress(0);
  //
  // // triggered after each item is loaded
  // imgLoad.on( 'progress', function() {
  //     // increase the number of loaded images
  //     loadedImageCount++;
  //     // update progress
  //     updateProgress( loadedImageCount );
  // });
  //
  // // update the progress of our progressBar tween
  // function updateProgress( value ) {
  //     // console.log(value/imageCount)
  //     // tween progress bar tween to the right value
  //     gsap.to(progressTween, {progress: value/imageCount, duration: 0.3, ease: 'power1.out'})
  // }
  //
  // // do whatever you want when all images are loaded
  // imgLoad.on( 'done', function( instance ) {
  //     // we will simply init our loader animation onComplete
  //     gsap.set(progressBar, {autoAlpha: 0, onComplete: initPageTransitions()});
  // });
}

function initVideo(){
  
  if(document.getElementById("video_cover")){
    var player = document.getElementById("video_cover");
    var source = document.getElementById("video_cover").querySelector('source');

    
    var isMobile = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
        },
        any: function() {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
        }
    };
    
    // player.addEventListener('webkitbeginfullscreen', onVideoBeginsFullScreen, false);
    // player.addEventListener('webkitendfullscreen', onVideoEndsFullScreen, false);
    if (!isMobile.any()) {
      source.setAttribute('src', 'https://player.vimeo.com/external/639964422.sd.mp4?s=e973845aadb6ad162b2cd2f65b0683a05cefe4ad&profile_id=165');
      player.load();
      player.play();      
    } else{      
      source.setAttribute('src', 'https://player.vimeo.com/external/639964422.sd.mp4?s=e973845aadb6ad162b2cd2f65b0683a05cefe4ad&profile_id=164');
      player.load();
      player.play();      
    }
    
      
    // if (!(navigator.userAgent.match(/iPhone|iPod/i))) {
//       player.play();
//     }
  }
  
  
}

//Init functions after load
function initContent() {
  
    // console.log("Init content")
    select('body').classList.remove('is-loading')

    //Disable current link
    var links = document.querySelectorAll('a[href]');
    var cbk = function(e) {
     if(e.currentTarget.href === window.location.href) {
       e.preventDefault();
       e.stopPropagation();
     }
    };

    for(var i = 0; i < links.length; i++) {
      links[i].addEventListener('click', cbk);
    }
  
    // // initNavigation();
    
    // initSmoothScrollbar();
    gsap.registerPlugin(ScrollTrigger);
    
    // initCTA();
    initMobileNav();
    // initHorizontalScroll();

    initImageParallax();
        
    initMap();
    
    // initProductVariant();
    initProductVariant();
    
    initScrolltrigger();

    // //Init Slideshow if present
    // var element = document.querySelector('.slideshow');
    // if(typeof(element) != 'undefined' && element != null){
    //   const slideshow = new Slideshow(element);
    // }


    // //Init FAQ if present
    const questions = document.querySelectorAll(".trigger")
    for (var i = 0; i < questions.length; i++) {
        questions[i].addEventListener("click", function() {
            this.querySelector('i').classList.toggle("rotate-45");
            this.querySelector('.trigger_target').classList.toggle("hidden");
        });
    }
    
    //Init video if present
    var player = document.getElementById("video_cover");
    if(document.getElementById("video_cover")){    
      player.play(); 
    }
    
    //Ticker
    var x =  document.getElementById('news');
    if (typeof(x) != 'undefined' && x != null)
    {
      var topics = document.querySelector('.topics').cloneNode(true);
      document.getElementById("news").appendChild(topics);
      TweenMax.to('.topics',22,{x:-topics.offsetWidth,repeat:-1,ease:Linear.easeNone});
    }
    
    //Fader
    // if (!window.AnimationEvent) { return; }
    // var fader = document.getElementById('fader');
    // fader.classList.add('fade-out');
    

}


//*************************************
// Page transitions
//*************************************

function pageTransitionIn({container}) {
  const loader = select('.loader');
  const loaderInner = select('.loader .inner');
  const loaderMask = select('.loader .mask');
    // timeline to stretch the loader over the whole screen
    const tl = gsap.timeline({
        defaults: {
            duration: 0.8,
            ease: 'power1.inOut'
        }
    });
    tl
        .set(loader, { autoAlpha: 1 })
        .fromTo(loader, { yPercent: -100 }, {yPercent: 0 })
        .to(container, { y: 150}, 0);
    return tl;
}

function pageTransitionOut({container}) {
  const loader = select('.loader');
  const loaderInner = select('.loader .inner');
  const loaderMask = select('.loader .mask');
    // timeline to move loader away down    
    const tl = gsap.timeline({
        defaults: {
            duration: 0.8,
            ease: 'power1.inOut'
        },
        onComplete: () => initContent()
    });
    tl
        .to(loader, { yPercent: 100 })
        .from(container, { y: -150}, 0)
        .set(loader, { autoAlpha: 0 });
        
    return tl;
}

function initPageTransitions() { 
    // barba.use(barbaPrefetch);
  
    // do something before the transition starts
    barba.hooks.before(() => {
        select('html').classList.add('is-transitioning');
    });
    // do something after the transition finishes
    barba.hooks.after(() => {
        select('html').classList.remove('is-transitioning');
        // bodyScrollBar.update();
        
    });

    // scroll to the top of the page
    barba.hooks.enter(() => {        
        window.scrollTo(0, 0);
        // bodyScrollBar.scrollTop = 0;
    });

    barba.init({        
        transitions: [{
            once() {
                // do something once on the initial page load
                initLoader();                                
            },
            async leave({current}) {
              
                // animate loading screen in
                await pageTransitionIn(current);
                 
            },
            enter({next}) {
                // animate loading screen away
                pageTransitionOut(next);
            },
        }]
    });
}

function initLoader() {
    // select('html').classList.add('is-transitioning');
      
    const tlLoaderIn = gsap.timeline({
        id: 'tlLoaderIn',
        defaults: {
            duration: 0.8,
            ease: 'power2.out'
        },
        onComplete: () => initContent()
    });

    const loader = select('.loader');

    const intro = select('#loader');            
    const image = select('.loader-image img');
    const mask = select('.loader-image-mask');
    const line1 = select('.loader-title-mask:nth-child(1) span');
    const line2 = select('.loader-title-mask:nth-child(2) span');
    const lines = selectAll('.loader-title-mask');

    tlLoaderIn
        .set(intro, {autoAlpha: 1})
        .to(intro, {
            scaleY: 1,
            transformOrigin: 'bottom',
            ease: 'power1.inOut'
        })
        .addLabel('revealImage')
        .from(mask, {yPercent: 100}, 'revealImage-=0.6')
        .from(image, {yPercent: -80}, 'revealImage-=0.6')                    
        .from([line1, line2], {yPercent: 100, stagger: 0.1}, 'revealImage-=0.4');

    const tlLoaderOut = gsap.timeline({
        id: 'tlLoaderOut',
        defaults: {
            duration: 1,
            ease: 'power2.inOut'
        },
        delay: 1
    });

    tlLoaderOut
        .to(lines, {yPercent: -500, stagger: 0.2}, 0)
        .to([intro, loader], {yPercent: -100}, 0.2)
        .from('header, main', {y: 150}, 0.2);

    const tlLoader = gsap.timeline();
    tlLoader
        .add(tlLoaderIn)
        .add(tlLoaderOut);
}


//*************************************
// Mobile Navigation
//************************************* 

// function initMobileNav(){
//
//   var t1 = new TimelineMax({ paused: true });
//   var setValues = gsap.set("#mobile_menu ul > li, .stagger_right, .burger_close", { autoAlpha: 1, y: 0, x: 0 });
//
//   t1.to("#mobile_menu", 1, {
//     top: 0,
//     ease: Expo.easeInOut,
//   });
//
//   t1.staggerFrom(
//     "#mobile_menu ul > li, .stagger_right",
//     0.8,
//     { y: -75, autoAlpha: 0, ease: Expo.easeOut },
//     "0.1",
//     "-=0.4"
//   );
//
//   t1.staggerFrom(
//     ".burger_close",
//     0.8,
//     { x: 100, autoAlpha: 0, ease: Expo.easeOut },
//     "0.1",
//     "-=0.75"
//   );
//
//   t1.reverse();
//
//   var elements = document.querySelectorAll(".burger")
//   for (var i = 0; i < elements.length; i++) {
//       elements[i].addEventListener("click", function(){
//         t1.play().timeScale(1)
//       });
//   }
//
//   var elements_2 = document.querySelectorAll(".burger_close")
//   for (var i = 0; i < elements_2.length; i++) {
//       elements_2[i].addEventListener("click", function(){
//         t1.timeScale(1.5).reverse().eventCallback("onReverseComplete", function(){
//            t1.add(setValues);
//         });
//       });
//   }
//
//
//   const ss = selectAll("#mobile_menu ul > li > a")
//
//   for (var i = 0; i < ss.length; i++) {
//     ss[i].addEventListener("click", function(event){
//
//       t1.timeScale(2.5).reverse().eventCallback("onReverseComplete", function(){
//         t1.add(setValues);
//         //gsap.set("#mobile_menu", { height: 0 });
//       });
//     });
//   }
//
// }
function bodyScroll(){
  //Lock scrolling on body
  var body = document.querySelector("body");
  body.classList.add("fixed");  
}

function initMobileNav(){  
  var body = document.querySelector("body");
  var nav = document.querySelector("nav");
  var mobile_nav = document.querySelector("#mobile_nav");
  
  var tl = new TimelineMax({ paused: true, onComplete: bodyScroll });
  
  //Remove border before start
  tl.set(nav, {borderColor: "transparent"})
  tl.set(mobile_nav, {display: "none"})
  mobile_nav.classList.remove("hidden")
  
  //Fade in mobile menu
  tl.from("#mobile_nav", 0.5, {
    top: 0,
    opacity: 0,  
    display: 'none',
    ease: Expo.easeInOut,
  });
    
  //Stagger in links
  tl.staggerFrom(
    "#mobile_nav_links > *, #mobile_nav_meta",
    1,
    {
      y: 50,
      opacity: 0,
      ease: Expo.easeOut,
    },
    "0.1",
    "-=0.2"
  ); 
  
  //Timeline for burger
  var tl_hamburger = new TimelineMax({yoyo: true, paused: true }),
  p1 = document.querySelector('#mobile_trigger #p1'),
  p2 = document.querySelector('#mobile_trigger #p2'),
  p3 = document.querySelector('#mobile_trigger #p3');

  //Animate burger 
  tl_hamburger
    .add('start')
    .to(p1, 1, { y: +15, ease: Power3.easeOut }, 'start')
    .to(p3, 1, { y: -15, ease: Power3.easeOut }, 'start')
    .to(p2, 0.001, {autoAlpha: 0})
    .add('rotate')
    .to(p1, 1, { transformOrigin: "50% 50%", rotation: 45,  ease: Power3.easeOut},'rotate')
    .to(p3, 1, { transformOrigin: "50% 50%", rotation: -45, ease: Power3.easeOut},'rotate');

  tl_hamburger.timeScale(8);
  
  //Menu trigger
  var mobile_trigger = document.querySelector("#mobile_trigger");

  if(typeof(mobile_trigger) != 'undefined' && mobile_trigger != null){
    mobile_trigger.addEventListener("click", function(){
            
      if (mobile_trigger.classList.contains('is-active')){      
        tl_hamburger.reverse();
        tl.reverse();
        body.classList.remove("fixed");
        // mobile_nav.classList.remove("hidden");
      } else{        
        tl_hamburger.play();
        tl.play().timeScale(1); 
      }
      
      //Mark menu as open
      mobile_trigger.classList.toggle("is-active");  
    });
  }
}


function initCTA(){
  
	var t1 = new TimelineMax({ paused: true });

  var setValues = gsap.set("#cta_modal img", { scale: 1 });
  var setValuesb = gsap.set(".ca", { scaleX: 1 });
  
	t1.to("#cta_modal", 1, {
		top: 0,
		ease: Expo.easeInOut,
	});
  
	t1.staggerFrom(
		"#cta_modal img",
		2.0,
		{ scale: 1.20, ease: Expo.easeOut },
		"0.1",
		"-=0.5"
	);
  
  
	t1.staggerFrom(
		".ca ",
		0.75,
		{
        scaleX: 0,
        force3D: true,
        ease: Expo.easeOut
    },
		"0.1",
		"-=3.0"
	);


	t1.reverse();
	
  const ele = selectAll("a[href^='#cta_open']")
  for (var i = 0; i < ele.length; i++) {
      ele[i].addEventListener("click", function(){  
        t1.play().timeScale(1)
      });   
  }
  
  
  var cta_close = document.querySelector('.cta_close')
  if(typeof(cta_close) != 'undefined' && cta_close != null){  
    cta_close.addEventListener("click", function(){
      t1.timeScale(1.5).reverse()
    });
  };
  
  
  const el2 = selectAll(".trigger")
  for (var i = 0; i < el2.length; i++) {
      el2[i].addEventListener("click", function(){ 
        t1.timeScale(1.5).reverse().eventCallback("onReverseComplete", function(){
           t1.add(setValues);
           t1.add(setValuesb);
        });
        // gsap.to('.trigger', {rotation: 45, duration: 1});
        // document.querySelector('.trigger i').classList.toggle("rotate-45");
        // document.querySelector('.trigger i').classList.toggle("opacity-75");
        // document.getElementById('xl').classList.toggle("hidden");
      });   
  }
 
}


//*************************************
// Scrolltrigger
//************************************* 
//Back top top
function initScrolltrigger(){  
  
  //Back top top
  const btt = document.querySelector('.back-to-top-button');
   
  ScrollTrigger.matchMedia({  
    // desktop
    "(min-width: 800px)": function() {
      gsap.set(btt, {y: 70, autoAlpha: 1});  
      gsap.to(btt, {
        y: 0, 
        scrollTrigger: {
          trigger: "body",
          start: "top -300px",
          end: "top -300px",
          toggleActions: "play none reverse none"
        }      
      })  
    }  
  });
  
  btt.addEventListener("click", function(){    
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    return false;
  });
  
}

//*************************************
// Commerce
//************************************* 

function updateShippingPrice(){
  var rbs = document.querySelectorAll('input[name="order[shipping_method_id]"]');
  var selectedValue;
  for (var rb of rbs) {
    if (rb.checked) {
      selectedValue = rb.value;
      break;
    }
  }
  alert(selectedValue);
}

function initProductVariant() {
  
  document.addEventListener('input', function (event) {

    // Only run on our select menu
    if (event.target.id !== 'variant') return;

    var elem = document.querySelector('#price');
    var price = event.target.options[event.target.selectedIndex].dataset.price;
    
    elem.textContent = price;

    // console.log(price);

    // The selected option element
    // console.log(event.target.options[event.target.selectedIndex]);

  }, false);

  //Checkout
  document.addEventListener('input', function (event) {
    // Only run on our select menu
    if (event.target.name !== 'order[shipping_method_id]') return;
    var elem_shipping = document.querySelector('#shipping');    
    var elem = document.querySelector('#total');  
      
    var total = event.target.dataset.price;
    var shipping = event.target.dataset.shipping;
    
    elem_shipping.textContent = shipping;
    elem.textContent = total;

  }, false);
  
  
  //Gallery
  const current = document.getElementById("current");
  const opacity = 0.6;
  const imgs = document.querySelectorAll(".img");
  
  imgs.forEach(img => {
    img.addEventListener("click", (e) => {
      //reset opacity
      imgs.forEach(img => {img.style.opacity = 1;
      });
    current.srcset = e.target.srcset;
    //Set current thumb state
    e.target.style.opacity = opacity; 
    });
  });
  
}


//*************************************
// Maps
//*************************************

function initMap() {
  var mapDiv = document.getElementById('map');
  
  //Check if map exists
  // if (mapDivdocument.body.contains('map') != 'undefined' && element != null){
  if (mapDiv != 'undefined' && mapDiv != null){
    // The location of Uluru
    var uluru = {lat: 52.6003258, lng: 4.6988579};
    // The map, centered at Uluru
    var map = new google.maps.Map(mapDiv, {zoom: 16, center: uluru});
    // var map = new google.maps.Map(mapDiv, {zoom: 16, center: uluru});
    // The marker, positioned at Uluru
    // var marker = new google.maps.Marker({address: address, map: map});
    var address = mapDiv.dataset.address
    const geocoder = new google.maps.Geocoder();
    geocodeAddress(geocoder, map, address);
    
  }
}

function geocodeAddress(geocoder, resultsMap, address) {
  // const address = "Kennemerstraatweg 464";
  geocoder.geocode({ address: address }, (results, status) => {
    if (status === "OK") {
      resultsMap.setCenter(results[0].geometry.location);
      new google.maps.Marker({
        map: resultsMap,
        position: results[0].geometry.location,
      });
    } else {
      alert("Geocode was not successful for the following reason: " + status);
    }
  });
}

//*************************************
// Utils
//*************************************

function initSmoothScrollbar() {
    
  bodyScrollBar = Scrollbar.init(select('#viewport'), { damping: 0.07 });
  
  // remove horizontal scrollbar
  // bodyScrollBar.setPosition(0, 0); 
  bodyScrollBar.track.xAxis.element.remove();
  gsap.registerPlugin(ScrollTrigger);
  
  // keep ScrollTrigger in sync with Smooth Scrollbar
  ScrollTrigger.scrollerProxy(document.body, {
    scrollTop(value) {
      if (arguments.length) {
        bodyScrollBar.scrollTop = value; // setter
      }
      return bodyScrollBar.scrollTop;  // getter
    }
  });

  // when the smooth scroller updates, tell ScrollTrigger to update() too:
  bodyScrollBar.addListener(ScrollTrigger.update);


  //Fixed burger
  const fixedElem = select('.fixed-burger')  
  if(typeof(fixedElem) != 'undefined' && fixedElem != null){
    bodyScrollBar.addListener( status => {
      const offset = status.offset
  
      fixedElem.style.top = offset.y + 'px'
    })
  }

}



//*************************************
// Animation
//*************************************

function initImageParallax() {

  // FadeOut Header  
  // var element = document.querySelector('header');
  // if(typeof(element) != 'undefined' && element != null){
  //   gsap.to(select("header"), {
  //     opacity:0,
  //     scrollTrigger: {
  //       start: '150% bottom',
  //       end: '150% top',
  //       trigger: 'header',
  //       scrub: true
  //       // onUpdate: self => console.log("progress:", self.progress)
  //     }
  //   });
  // }
   
  
  // select all sections .with-zoom
  gsap.utils.toArray('.with-zoom').forEach(section => {
    // get the image
    let image = section.querySelector('img');
     
    //Set scale if not in viwport
    if (!ScrollTrigger.isInViewport(section)) {
      gsap.set(image, { scale: 1.33 })     
    }      
      
    // create tween for the image
    gsap.to(image, {
      scale: 1,
      ease: 'none',
      scrollTrigger: {
        trigger: section,
        start: 'top bottom',
        scrub: true
      }
    });
  });
  
      
  // select all sections .with-parallax
  gsap.utils.toArray('.with-parallax').forEach(section => {
    // get the image
    let image = section.querySelector('img');
      
    //Set scale if not in viwport
    if (!ScrollTrigger.isInViewport(section)) {
      gsap.set(image, { height: "120%", top: "-20%" })
    }     
      
    // create tween for the image
    gsap.to(image, {
      yPercent: 20,
      ease: 'none',
      scrollTrigger: {
        trigger: section,
        start: 'top bottom',
        scrub: true
      }
    });
  });
    
 
    
}


//*************************************
// Slideshow
//*************************************

class Slideshow {
	constructor(el) {

			this.DOM = {el: el};

			this.config = {
				slideshow: {
					delay: 4000,
					pagination: {
						duration: 3,
					}
				}
			};
			this.init();
	}


	init() {
		var self = this;
    var x = document.getElementById("plus_right");

		// Set the slider
		this.slideshow = new Swiper (this.DOM.el, {
				loop: true,
        autoplay: {
					delay: this.config.slideshow.delay,
					disableOnInteraction: false,
				},
				speed: 800,
				preloadImages: true,
				updateOnImagesReady: true,

				// Navigation arrows
				navigation: {
					nextEl: '#plus_right',
					prevEl: '#plus_left',
				},

				on: {
					init: function() {
						self.animate('next');
					},
          slideNextTransitionStart: function (swiper) {
            // console.log(swiper.activeIndex);
            document.getElementById("plus_left").innerHTML = "0" + (swiper.activeIndex-1);
            document.getElementById("plus_right").innerHTML = "0" + (swiper.activeIndex);
          },
				}

			});

			// Init/Bind events.
			this.initEvents();
	}

	initEvents() {
			this.slideshow.on('slideNextTransitionStart', () => this.animate('next'));
			this.slideshow.on('slidePrevTransitionStart', () => this.animate('prev'));
	}

	animate(direction = 'next') {

			// Get the active slide
			this.DOM.activeSlide = this.DOM.el.querySelector('.swiper-slide-active');
			this.DOM.activeSlideImg = this.DOM.activeSlide.querySelector('.slide img');

//			Animate background
			TweenMax.to(this.DOM.activeSlideImg, 1.5, {
					ease: Expo.easeOut,
					// startAt: {x: direction === 'next' ? 0 : -0},
					// startAt: {x: direction === 'next' ? 200 : -200},
					x: 0,
			});

			// const clip = (val, min, max) => Math.max(min, Math.min(val, max));
		  // const clippedProgress = clip(this.DOM.activeSlide.progress, -1, 1);
			// const scale = 1 + 0.05;
			// const opacity = Math.max(1 - Math.abs(this.DOM.activeSlide.progress), 0);
			//
			//
			// // you can do your CSS animation instead of using tweening.
			// TweenMax.to(this.DOM.activeSlideImg, 2, {
			// 	scale,
			// 	opacity,
			// });

			document.getElementById("state").style.width = "0";
			this.animatePagination()
	}


	animatePagination(swiper, paginationEl) {
		// console.log("Number:" + swiper);

		var st = document.getElementById("state");
		var a = document.getElementById("plus_left");
    
		TweenMax.to(st, 4, {
			width: '100%',
			ease: 'linear',
			onComplete: () => {
				// this.DOM.$slide_progress.css({width: '0'})
				// this.DOM.$slide_items.slick('slickNext')
				// let currentSlide = this.DOM.$slide_items.slick('slickCurrentSlide')
				// currentSlide += 1
				// this.DOM.$slide_counter.html("0"+currentSlide)
				// this.DOM.$slide_counter_left.html("0"+(currentSlide-1))
			}
		});

	}
}

