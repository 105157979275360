/*
<div data-controller="sidecar">
  <a href="#" data-action="click->sidecar#open">
    <span>Open Modal</span>
  </a>

  <!-- Modal Container -->
  <div data-target="sidebar.container">
   </div>
</div>
*/

// import TweenLite from "gsap/TweenLite";
// import TweenMax from "gsap/TweenMax";

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  static targets = [ "quantity", "price", "total" ]
  

  connect() {

    // this.toggleClass    = this.data.get('class') || 'hidden'
  }
  
  remove(e){
    // e.preventDefault()
    console.log( 'removing')
    // this.itemTarget.toggleClass = 'hidden'
  }
  
  
}